import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  if (process.client) {
    // 兼容低版本浏览器
    function getIOSVersion(ua) {
      const match = ua.match(/OS (\d+)_(\d+)_?(\d+)?/);
      if (match && match.length > 1) {
        const majorVersion = parseInt(match[1], 10);
        const minorVersion = parseInt(match[2], 10);
        return { majorVersion, minorVersion };
      }
      return null;
    }

    function isIOSBelow(versionThreshold) {
      const ua = navigator.userAgent;
      if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
        const iosVersion = getIOSVersion(ua);
        if (iosVersion) {
          const { majorVersion, minorVersion } = iosVersion;
          const [thresholdMajor, thresholdMinor] = versionThreshold.split('.').map(Number);
          return (
            majorVersion < thresholdMajor ||
            (majorVersion === thresholdMajor && minorVersion < thresholdMinor)
          );
        }
      }
      return false;
    }

    // 调用示例：传入阈值版本号
    if (isIOSBelow('15.2')) {
      console.log('iOS 15.2 或以下版本，使用替代方案');
      window.logStatusChangeBroadcast = {
        postMessage: () => {}
      }
      return
    }

    // let logStatusChanged
    // 初始化广播
    window.logStatusChangeBroadcast = new BroadcastChannel('logStatusChange');

    // 监听广播
    window.logStatusChangeBroadcast.onmessage = (event) => {
      console.log('Received message:', event.data);
      if (event.data === 'change') {
        // logStatusChanged = true
        nextTick().then(() => {
          window.location.reload()
        })
      }
    };

    // 监听tab切换至前台
    document.addEventListener('visibilitychange', () => {
      // if (document.visibilityState === 'visible') {
      //   console.log('App onShow');
      //   这里可以放置从后台切换到前台时执行的逻辑
        // if (logStatusChanged) window.location.reload()
      // } else {
      //   console.log('App onHide');
      //   这里可以放置标签页进入后台时执行的逻辑
      // }
    });
  }
});
