export function Device(userAgent) {
  const uaString = userAgent.toLowerCase();
  const os = {
    ios: !!uaString.match(/(iphone|ipod|ipad)/),
    android: !!(uaString.match(/(android)/) || uaString.match(/(linux)/)),
    wp: !!uaString.match(/(windows phone)/),
    symbian: !!uaString.match(/(symbianos)/),
  }
  return {
    os,
    mobile: os.ios || os.android || os.wp || os.symbian
  }
}
