import {defineNuxtPlugin} from '#app';
import {ElNotification} from "element-plus";
import IconInfo from "@/assets/img/icon-info.svg"
import IconSparkles from "@/assets/img/sparkles.svg"
import IconCube from "@/assets/img/icon-cube.svg"
import IconLock from "@/assets/img/icon-locked.svg"
import IconUnLock from "@/assets/img/lock-open.svg"
import IconGift from "@/assets/img/icon-gift.svg"
import IconExclamation from "@/assets/img/icon-exclamation-circle.svg"

const message = ({icon, title, message}) => {
  return h(
    // container
    "div",
    {style: {display: "flex", flexDirection: "column", gap: "8px"}},
    [
      // icon & title
      h(
        "div",
        {style: {display: "flex", alignItems: "center", gap: "8px"}},
        [
          h("img", {src: icon, style: {width: "32px", height: "32px", padding: "4px"}}),
          h("span", {
            style: {
              fontFamily: "TWK Lausanne",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "650",
              lineHeight: "18px",
              letterSpacing: "-0.056px",
              textTransform: "uppercase"
            }
          }, title),
        ]
      ),
      // message
      h("span", {
        style: {
          fontFamily: "TWK Lausanne",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: "300",
          lineHeight: "normal",
          whiteSpace: "break-spaces"
        }
      }, message),
    ])
}

export default defineNuxtPlugin(async nuxtApp => {
  if (process.client) {
    class BNTOMessage {
      constructor() {
        this.info = (options) => {
          ElNotification({
            message: message({icon: IconInfo, title: options.title, message: options.message}),
            dangerouslyUseHTMLString: true,
            customClass: 'custom-notification', // 自定义类名
            duration: 0,
          });
        }
        this.sparkle = (options) => {
          ElNotification({
            message: message({icon: IconSparkles, title: options.title, message: options.message}),
            dangerouslyUseHTMLString: true,
            customClass: 'custom-notification', // 自定义类名
            duration: 0,
          });
        };
        this.cube = (options) => {
          ElNotification({
            message: message({icon: IconCube, title: options.title, message: options.message}),
            dangerouslyUseHTMLString: true,
            customClass: 'custom-notification', // 自定义类名
            duration: 0,
          });
        }
        this.lock = (options) => {
          ElNotification({
            message: message({icon: IconLock, title: options.title, message: options.message}),
            dangerouslyUseHTMLString: true,
            customClass: 'custom-notification', // 自定义类名
            duration: 0,
          });
        }
        this.unlock = (options) => {
          ElNotification({
            message: message({icon: IconUnLock, title: options.title, message: options.message}),
            dangerouslyUseHTMLString: true,
            customClass: 'custom-notification', // 自定义类名
            duration: 0,
          });
        }
        this.gift = (options) => {
          ElNotification({
            message: message({icon: IconGift, title: options.title, message: options.message}),
            dangerouslyUseHTMLString: true,
            customClass: 'custom-notification', // 自定义类名
            duration: 0,
          });
        }
        this.error = (options) => {
          ElNotification({
            message: message({icon: IconExclamation, title: options.title || "Error Message", message: options.message}),
            dangerouslyUseHTMLString: true,
            customClass: 'custom-notification', // 自定义类名
            duration: 0,
          });
        }
      }
    }

    nuxtApp.provide('BNTOMessage', new BNTOMessage)
  }
});
