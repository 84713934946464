import {defineNuxtPlugin} from '#app';
import {useBaseStore} from "~/stores/base";
import {appInit} from "~/api/api.app";
import Cookies from "js-cookie";

export default defineNuxtPlugin(async () => {
  const route = useRoute()
  const source = route.query.source
  if (source) useBaseStore().updateXBntoSource(source)

  try {
    const initRes = await appInit()
    useBaseStore().updateAppInit(initRes.result)
    console.log("[init]", initRes.result)
  } catch (e) {
    console.error("[init]", e)
  }

  if (process.client) {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    console.log(`Screen Resolution: ${screenWidth}x${screenHeight}`);
    Cookies.set("resolution", `${screenWidth}x${screenHeight}`, {
      path: "/",
      domain: ".bnto.rent",
      expires: 365 // 365 天后过期
    });

    if (navigator.connection) {
      const connectionType = navigator.connection.effectiveType;
      console.log(`Network Type: ${connectionType}`);
      Cookies.set("network", connectionType, {
        path: "/",
        domain: ".bnto.rent",
        expires: 365 // 365 天后过期
      });
    } else {
      console.log('Network information is not supported in this browser.');
    }

    const language = navigator.language || navigator.userLanguage;
    console.log(`Device Language: ${language}`);
    Cookies.set("language", language, {
      path: "/",
      domain: ".bnto.rent",
      expires: 365 // 365 天后过期
    });

    const deviceId = useBaseStore().getterDeviceId
    console.log(`deviceId: ${deviceId}`);
    Cookies.set("deviceId", deviceId, {
      path: "/",
      domain: ".bnto.rent",
      expires: 365 // 365 天后过期
    });
  }
});
