import { defineNuxtPlugin } from '#app';
import {useBaseStore} from "~/stores/base";

export default defineNuxtPlugin(() => {
  if (process.client) {
    useBaseStore().updateViewportHeight(window.innerHeight)
    window.addEventListener('resize', () => {
      useBaseStore().updateViewportHeight(window.innerHeight)
    })
  }
});
