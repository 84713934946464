import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  if (process.client) {
    window.addEventListener("klaviyoForms", function(e) {
      if (e.detail.type === 'open') {
        // Custom JS
        console.log("klaviyoForms opened")
      }
    });

    window.addEventListener("klaviyoForms", function(e) {
      if (e.detail.type === 'submit') {
        console.log("klaviyoForms submitted")
        // Meta Pixel 埋点 BNTO-1482
        fbq('track', 'CompleteKlaviyoBNTOSignUp', {
          content_name: 'Klaviyo BNTO Sign Up Form', // 广告位的名称或标识
          content_type: 'ad',
          ad_position: window.location.href, // 你可以自定义的位置标识
          click_action: 'complete_Klaviyo_BNTO_Sign_Up' // 描述点击的动作
        });
      }
    });
  }
});
