import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function memberQuery() {
  return omniFetch({
    url: config.v1Path + config.memberQueryPath,
    method: "post",
    params: {}
  })
}

export function memberUnPause() {
  return omniFetch({
    url: config.v1Path + config.memberUnPausePath,
    method: "post",
    params: {}
  })
}

export function memberPause() {
  return omniFetch({
    url: config.v1Path + config.memberPausePath,
    method: "post",
    params: {}
  })
}

export function memberCancel({surveyList, feedback, willingness}) {
  return omniFetch({
    url: config.v1Path + config.memberCancelPath,
    method: "post",
    params: {
      surveyList, feedback, willingness
    }
  })
}

export function memberCancelSurvey() {
  return omniFetch({
    url: config.v1Path + config.memberCancelSurveyPath,
    method: "get",
    params: {}
  })
}
