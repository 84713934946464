import { defineNuxtPlugin } from '#app'
import {googleTrackPageView} from "~/utils/google-analyze-track";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    nuxtApp.hook('page:finish', (ctx) => {
      window.dataLayer = window.dataLayer || [];
      const route = nuxtApp.$router.currentRoute.value; // 获取当前路由信息
      // google 埋点，页面浏览
      googleTrackPageView(route)
    });
  }
})
