export const config = {
  v1Path: "/v1",
  authGetTokenPath: "/auth/get-token",
  homepageIndexPath: "/homepage/index",
  emailSendVerificationCodePath: "/email/send-verification-code",
  emailValidateCodePath: "/email/validate-code",
  emailEditSubscribePath: "/email/editSubscribe",
  emailReferralPath: "/email/referral",
  appInitPath: "/app/init",
  appDynamicPath: "/app/dynamic",
  userSignUpPath: "/user/sign-up",
  userRentalHistoryPath: "/user/rental-history",
  userLoginPath: "/user/login",
  userLogoutPath: "/user/log-out",
  userDeletePath: "/user/delete",
  userResetPasswordPath: "/user/reset-password",
  addressEditPath: "/address/edit",
  addressQueryPath: "/address/query",
  profileInfoPath: "/profile/info",
  profileQueryPath: "/profile/query",
  profileEditPath: "/profile/edit",
  profileQueryNotifyConfigPath: "/profile/query-notify-config",
  profileModifyNotifyConfigPath: "/profile/modify-notify-config",
  paymentEditPath: "/payment/edit",
  paymentQueryPath: "/payment/query",
  couponValidatePath: "/coupon/validate",
  couponSendPath: "/coupon/send",
  memberQueryPath: "/member/query",
  memberUnPausePath: "/member/unPause",
  memberPausePath: "/member/pause",
  memberCancelPath: "/member/cancel",
  memberCancelSurveyPath: "/member/cancel-survey",
  basicProductListPath: "/basic/productList",
  basicProductListByOccasionPath: "/basic/productListByOccasion",
  basicProductListByCollectionPath: "/basic/productListByCollection",
  basicProductListByNewArrivalsPath: "/basic/productListByNewArrivals",
  basicProductListByViaviaPath: "/basic/productListByViavia",
  basicProductListByBrandPath: "/basic/productListByBrand",
  basicProductListByRecentlyViewedPath: "/basic/productListByRecentlyViewed",
  basicProductListByYouLikePath: "/basic/productListByYouLike",
  basicProductListBySimilarItemsPath: "/basic/productListBySimilarItems",
  brandsInfoPath: "/brands/info",
  brandsListPath: "/brands/list",
  brandsGetBrandNameListPath: "/brands/getBrandNameList",
  productInfoPath: "/product/info",
  productReviewListPath: "/product/review/list",
  productReviewRecommendListPath: "/product/review/recommendList",
  productSkuListPath: "/product/skuList",
  productReviewAddPath: "/product/review/add",
  productReviewInfoPath: "/product/review/info",
  wishlistListPath: "/wishlist/list",
  wishlistAddPath: "/wishlist/add",
  wishlistDelPath: "/wishlist/del",
  menuIndexPath: "/menu/index",
  menuCategoryListPath: "/menu/category/list",
  filterInfoPath: "/filter/info",
  filterSearchBeforePath: "/filter/searchBefore",
  filterSearchPath: "/filter/search",
  wishlistBrandListPath: "/wishlist/brand/list",
  wishlistBrandAddPath: "/wishlist/brand/add",
  wishlistBrandDelPath: "/wishlist/brand/del",
  orderPrePayDetailPath: "/order/pre-pay-detail",
  orderCreatePath: "/order/create",
  orderRentalHistoryPagePath: "/order/rental-history-page",
  orderRentalHistoryDetailPath: "/order/rental-history-detail",
  orderReceiptListPath: "/order/receipt-list",
  orderReceiptDetailPath: "/order/receipt-detail",
  orderQueryPayResultPath: "/order/queryPayResult",
  bntoQueryPath: "/bnto/query",
  bntoQueryPdpButtonPath: "/bnto/query/pdp-button",
  cartEditPath: "/cart/edit",
  cartRemoveSkuPath: "/cart/removeSku",
  waitlistSignupPath: "/waitlist/signup",
  pointQueryPath: "/point/query",
  pointQueryLogPath: "/point/query/log",
  pointReferralLogPath: "/point/referral/log",
  pointReferralCodeCheckPath: "/point/referral/code-check",

  aiHistoryListPath: "/ai/history/list",
  aiHistoryClearPath: "/ai/history/clear",
  fileAwsGenerateUploadedFileKeyPath: "/file/aws/generateUploadedFileKey",

  BUSINESS_CODE: {
    EMAIL_EXIST: 20001,
    DATA_IS_NOT_FOUND: 20003,
    EMAIL_NOT_EXIST: 20008,
    EMAIL_OR_PASSWORD_ERROR: 20011,
    NOT_IN_WAITLIST: 20013,
    WAITING_IN_WAITLIST: 20014,
    INVALID_USER_NICKNAME: 20018,
    STRIPE_EXECUTE: 30007,
    PAYMENT_RESULT_CHECK: 30008,
    UNAUTHORIZED: 40000,
    INVALID_PROMO_CODE: 50000,
    PROMO_CODE_USED: 50001,
    PROMO_CODE_EXCLUDED_PRODUCT: 50002,
    INSUFFICIENT_POINTS: 50003,
    PROMO_CODE_FIRST_SUBSCRIBE: 50004,
    INVALID_INVITATION_CODE: 50005,
    SERVER_ERROR: 60001,
    TOKEN_EXPIRED: 60005,
    PRODUCT_OFF_SHELF: 70003
  },
}
