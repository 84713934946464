import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function bntoQuery({type, orderId}) {
  return omniFetch({
    url: config.v1Path + config.bntoQueryPath,
    method: "get",
    params: {
      type, // 1 购物车 2 bnto订单
      orderId, // BNTO订单需要
    }
  })
}

export function bntoQueryPdpButton({sku}) {
  return omniFetch({
    url: config.v1Path + config.bntoQueryPdpButtonPath,
    method: "get",
    params: {
      sku
    }
  })
}
