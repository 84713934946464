// 邮箱校验
import {useBaseStore} from "~/stores/base";
import {useCartStore} from "~/stores/cart";
import {navigateTo} from "#app";
import {appDynamic} from "~/api/api.app";
import {PATH_LOGIN, PATH_PAYMENT_RESULT_CHECK, URL_PAYMENT_RESULT_CHECK} from "~/utils/constants";
import {memberQuery} from "~/api/api.member";
import {bntoQuery} from "~/api/api.bnto";
import SmartLook from "smartlook-client";
import {tiktokPixelIdentify} from "~/utils/tiktok-track";

export function nameValid(name) {
  const reg = /^[A-Za-z\s]+$/;
  return reg.test(name);
}

export function emailValid(email) {
  const reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
  return reg.test(email);
}

// 字母校验
export function hasLetter(str) {
  const reg = /[a-zA-Z]/;
  return reg.test(str);
}

// 大写校验
export function hasUpperCase(str) {
  const reg = /[A-Z]/;
  return reg.test(str)
}

// 小写校验
export function hasLowerCase(str) {
  const reg = /[a-z]/;
  return reg.test(str)
}

// 数字校验
export function hasNumber(str) {
  const reg = /\d/;
  return reg.test(str)
}

// 特殊字符校验
export function hasSpecialCharacter(str) {
  const reg = /[!@#$%^&*]/;
  return reg.test(str)
}

// 倒计时
export function timeCountdown(time, callback) {
  const timer = setInterval(() => {
    time--;
    callback(time);
    if (time === 0) {
      clearInterval(timer);
    }
  }, 1000);
}

/** 信用卡format **/
export function insertSpacesAfterEveryFourCharacters(str) {
  if (!str || typeof str !== 'string') {
    return ''; // 如果不是字符串，则返回空字符串
  }
  str = removeAllSpaces(str)
  let result = '';
  for (let i = 0; i < str.length; i++) {
    // 如果当前索引是4的倍数（不包括0），则添加空格
    if ((i + 1) % 4 === 0 && i !== str.length - 1) {
      result += str[i] + ' ';
    } else {
      result += str[i];
    }
  }

  return result;
}

export const updateCart = async () => {
  const orderId = useBaseStore().getterUserInfo.bntoOrderId
  const params = {}
  if (orderId) {
    params.type = 2;
    params.orderId = orderId
  } else {
    params.type = 1
  }
  const resCart = await bntoQuery(params)
  useCartStore().updateTopInfo({
    ...resCart.result.topInfo,
    // status: BNTO_STATUS.RETURN_IN_TRANSIT,
    // purchasedNum: 2
  })
  console.log('[购物车信息]', useCartStore().getterTopInfo)
  useCartStore().updateCartItemList(resCart.result.productList?.map((v, k) => {
    // if (k === 0) {
    //   v.btnList = [{type: "BUY"}]
    //   v.skuStatus = SKU_STATUS.NORMAL;
    // } else if (k === 1) {
    //   v.skuStatus = SKU_STATUS.PURCHASED;
    // } else if (k === 2) {
    //   v.skuStatus = SKU_STATUS.ISSUE_WITH_RETURN;
    // }else if (k === 3) {
    //   v.skuStatus = SKU_STATUS.OUT_OF_STOCK;
    // }
    // v.skuStatus = SKU_STATUS.OUT_OF_STOCK;
    // if (k === 0) {
    //   v.skuStatus = SKU_STATUS.NORMAL;
    // }
    // v.discount = 50
    // salePrice
    // originalPrice
    // discount
    return v
  }) || [])
  console.log('[购物车]', useCartStore().getterCartItemList)
}

export const globalUpdateUserInfo = async () => {
  try {
    const resAppDynamic = await appDynamic()
    const resMemberQuery = await memberQuery()
    useBaseStore().updateUserInfo({
      bntoUpperLimit: 6, // bnto item 数量上限
      ...resAppDynamic.result,
      ...resMemberQuery.result,
      // havingBnto: 0, // mock
      // havingBntoStatus: HAVING_BNTO_STATUS.DELIVERED, // mock
      // isMember: 0, // mock
      // subStatus: SUB_STATUS.CANCELLED, // mock
      // pausedExpired: 1, // mock // 0 未过期 触发unPaused、1 已过期 触发resume Subscribe
    })
    console.log('[userInfo]', useBaseStore().getterUserInfo)
    await updateCart()
    smartLookIdentify()
    await tiktokPixelIdentify()
    console.log("globalUpdateUserInfo finished")
  } catch (e) {
    console.error("globalUpdateUserInfo", e)
  }
}

export const toLogin = (options) => {
  let queryStr = "?"
  if (options && options.query) {
    for (const queryKey in options.query) {
      queryStr += `${queryKey}=${encodeURIComponent(options.query[queryKey])}&`
    }
  }
  if (process.client && options && options.query && !options.query.redirect_uri) {
    queryStr += `redirect_uri=${encodeURIComponent(window.location.href)}&`
  }
  navigateTo(PATH_LOGIN + queryStr)
}

export const commonBeforeLogout = () => {
  if (localStorage) {
    /** 清空搜索记录 **/
    localStorage.setItem("SEARCH_HISTORY", JSON.stringify([]))
    /** 恢复引导入口 **/
    localStorage.setItem("ENTRANCE_COMPLETE_PREFERENCE_VISIBLE", "")
  }
}

export const commonDownloadApp = () => {
  useBaseStore().updateIsShowModalDownload(true)
}

export const removeAllSpaces = (str) => {
  return str.replace(/\s/g, '')
}

export const checkDeviceServerSide = () => {
  const device = useDevice()
  console.log("[Server Side][isMobile]", device.value.mobile)
  useBaseStore().updateIsMobile(device.value.mobile)
}

export const checkDeviceClientSide = () => {
  const device = useDevice()
  const isMobile = device.value.mobile || window.innerWidth <= 768
  useBaseStore().updateIsMobile(isMobile)
  console.log("[Client Side][isMobile]", isMobile)
  window.addEventListener('resize', e => {
    const isMobile = e.target.innerWidth <= 768
    useBaseStore().updateIsMobile(isMobile)
  })
}
/**
 * @typedef {Object} MyOptions
 * @property {boolean} hideForever - 是否永久隐藏
 */

/**
 * 处理请求的函数
 * @param {string} name - 名称
 * @param {MyOptions} options - 配置对象
 */
export const setStreamerStatusLocalStorage = (name, options = {}) => {
  // 记录次数
  const timesKey = `${import.meta.env.VITE_SERVER_ENV.toUpperCase()}_STREAMER_${name.toUpperCase()}_SHOWED_UP_TIMES`
  let times = Number(localStorage.getItem(timesKey) || 0)
  times++
  if (options.hideForever) times = 99999
  localStorage.setItem(timesKey, String(times))

  // 记录关闭时间
  const dateKey = `${import.meta.env.VITE_SERVER_ENV.toUpperCase()}_STREAMER_${name.toUpperCase()}_LAST_SHOWED_UP_DATE`
  localStorage.setItem(dateKey, String(new Date()))
}

export const getStreamerStatusLocalStorage = (name, {maxShowUpTimes, intervalTime}) => {
  console.log("横幅名称", name)
  // 获取显示次数
  const timesKey = `${import.meta.env.VITE_SERVER_ENV.toUpperCase()}_STREAMER_${name.toUpperCase()}_SHOWED_UP_TIMES`
  const times = Number(localStorage.getItem(timesKey) || 0)
  console.log("已经显示次数", times)
  console.log("最大显示次数", maxShowUpTimes)
  if (times >= maxShowUpTimes) return false
  // 获取间隔时间
  const dateKey = `${import.meta.env.VITE_SERVER_ENV.toUpperCase()}_STREAMER_${name.toUpperCase()}_LAST_SHOWED_UP_DATE`
  const dateLastTime = localStorage.getItem(dateKey) ? new Date(localStorage.getItem(dateKey)).getTime() : new Date().getTime() - intervalTime * 1000
  const dateNow = new Date().getTime()
  console.log("实际间隔秒数", (dateNow - dateLastTime) / 1000)
  console.log("要求间隔秒数", intervalTime)
  return (dateNow - dateLastTime) / 1000 >= intervalTime;
}

export function USMobileFormatter(mobile) {
  if (!mobile || mobile.length !== 10) return mobile
  return `(${mobile.slice(0, 3)}) ${mobile.slice(3, 6)}-${mobile.slice(6, 10)}`
}

export function sleep(milliseconds) {
  return new Promise(resolve => {
    console.log("asleep", new Date().getTime())
    setTimeout(() => {
      console.log("awake", new Date().getTime())
      resolve()
    }, milliseconds)
  })
}

function parseUserAgent(userAgent) {
  // 定义正则表达式来匹配主要的浏览器和操作系统信息
  const browserPatterns = {
    chrome: /Chrome\/([0-9.]+)/,
    firefox: /Firefox\/([0-9.]+)/,
    safari: /Version\/([0-9.]+).*Safari/,
    edge: /Edg\/([0-9.]+)/,
    opera: /OPR\/([0-9.]+)/
  };

  const osPatterns = {
    android: /Android ([0-9.]+)/,  // 优先匹配 Android 操作系统
    windows: /Windows NT ([0-9.]+)/,
    mac: /Mac OS X ([0-9_]+)/,
    linux: /Linux/,
    ios: /iPhone OS ([0-9_]+)/
  };

  const enginePatterns = {
    webkit: /AppleWebKit\/([0-9.]+)/,
    gecko: /Gecko\/[0-9.]+/
  };

  const devicePatterns = {
    iPhone: /iPhone/,
    iPad: /iPad/,
    iPod: /iPod/,
    Macintosh: /Macintosh/,
    Windows: /Windows/,
    Android: /Android/
  };

  // 初始化结果对象
  let browser = 'unknown';
  let browserVersion = 'unknown';
  let os = 'unknown';
  let osVersion = 'unknown';
  let engine = 'unknown';
  let engineVersion = 'unknown';
  let device = 'unknown';

  // 检查浏览器
  for (const [name, pattern] of Object.entries(browserPatterns)) {
    const match = userAgent.match(pattern);
    if (match) {
      browser = name.charAt(0).toUpperCase() + name.slice(1);
      browserVersion = match[1] ? match[1].toLowerCase() : 'unknown'; // 检查 match[1] 是否有效
      break;
    }
  }

  // 检查操作系统
  for (const [name, pattern] of Object.entries(osPatterns)) {
    const match = userAgent.match(pattern);
    if (match) {
      os = name === 'ios' ? 'iOS' : name.charAt(0).toUpperCase() + name.slice(1);
      osVersion = match[1] ? match[1].split('.')[0] : 'unknown'; // 确保只提取主版本号
      break;
    }
  }

  // 检查引擎
  for (const [name, pattern] of Object.entries(enginePatterns)) {
    const match = userAgent.match(pattern);
    if (match) {
      engine = name.charAt(0).toUpperCase() + name.slice(1);
      engineVersion = match[1] ? match[1].toLowerCase() : 'unknown'; // 确保 match[1] 有效
      break;
    }
  }

  // 检查设备
  for (const [name, pattern] of Object.entries(devicePatterns)) {
    const match = userAgent.match(pattern);
    if (match) {
      device = name; // 保持设备名称的标准拼写
      break;
    }
  }

  // 特殊处理 Safari 浏览器
  if (browser === 'Safari' && /Version\/([0-9.]+).*Safari/.test(userAgent)) {
    const versionMatch = userAgent.match(/Version\/([0-9.]+)/);
    if (versionMatch) {
      browserVersion = versionMatch[1].toLowerCase();
    }
  }

  return {
    browser,
    browserVersion,
    os,
    osVersion,
    engine,
    engineVersion,
    device
  };
}

export function parseCookieString(cookieString) {
  if (!cookieString) return {}
  // 分割字符串，去除每一项的空格并形成数组
  const pairs = cookieString.split(';').map(pair => pair.trim());

  // 将键值对存入对象
  const cookieObject = {};
  pairs.forEach(pair => {
    const [key, value] = pair.split('=');
    cookieObject[key] = decodeURIComponent(value);  // 解码URI成普通字符串
  });

  return cookieObject;
}

function valuesToSemicolonString(obj) {
  // 确保输入是一个对象
  if (typeof obj !== 'object' || obj === null) {
    throw new Error('Input must be a non-null object');
  }

  // 获取对象的所有值
  return Object.values(obj)
    .map(value => value === undefined ? 'undefined' : value === null ? 'null' : value)
    .join(';');
}

export function getXBntoUa(userAgent, cookie, deviceId) {
  const networkType = parseCookieString(cookie).network || "unknown_network"
  const resolution = parseCookieString(cookie).resolution || "unknown_resolution"
  const language = parseCookieString(cookie).language || "unknown_device_lang"
  const app_name = "BNTO"
  const app_version_code = "100000"
  const device_info = {
    device_type: parseUserAgent(userAgent).device,
    device_os: "web",
    device_os_version: parseUserAgent(userAgent).osVersion,
    device_lang: language,
    bnto_platform_name: "web",
    app_version_name: "1.0.0",
    bnto_site: "en-us",
    source: "unknown_source",
    bnto_device_id: deviceId,
    resolution: resolution,
    network: networkType,
  }
  const app_render = "web"
  const app_render_version = "1.0"
  return `${app_name}/${app_version_code} (${valuesToSemicolonString(device_info)}) ${app_render}/${app_render_version}`
}

export const weightNumberValidate = (weight) => {
  // 正则表达式：允许最多3位整数，最多2位小数
  const regex = /^\d{0,3}(\.\d{0,2})?$/;
  return regex.test(weight)
}

export const closeAllDrawers = () => {
  // 路由跳转时，统一关闭弹窗
  useBaseStore().updateIsShowDrawerSearch(false)
  useBaseStore().updateIsShowDrawerMenu(false)
  useBaseStore().updateIsShowDrawerAIMore(false)
}

export const handleStripe = async (payIntentClientSecret, orderId, returnUrl) => {
  /** 打开遮罩 **/
  useBaseStore().updateIsShowModalLoadingProgress(true)
  const stripe = useNuxtApp().$stripe
  const BNTOMessage = useNuxtApp().$BNTOMessage
  try {
    const retrievePaymentIntentRes = await stripe.retrievePaymentIntent(payIntentClientSecret)
    console.log("retrievePaymentIntent", retrievePaymentIntentRes)
    await handlePaymentIntent(retrievePaymentIntentRes, {orderId, returnUrl})
  } catch (e) {
    BNTOMessage.error({message: e.message});
  }
}

/**
 * @typedef {Object} PaymentIntentOptions
 * @property {String} returnUrl - 回调地址
 * @property {String} orderId - 订单ID
 */
/**
 * @param {Object} payment - payment对象
 * @param {PaymentIntentOptions} options - 配置对象
 */
const handlePaymentIntent = async (payment, options) => {
  const stripe = useNuxtApp().$stripe
  const BNTOMessage = useNuxtApp().$BNTOMessage
  console.log('payment', payment);
  if (payment.error) {
    /** 关闭遮罩 **/
    useBaseStore().updateIsShowModalLoadingProgress(false)
    const router = useRouter()
    // failed = 2
    await router.push(`${PATH_PAYMENT_RESULT_CHECK}?message=${encodeURIComponent(payment.error.message)}&status=2`)
    return
  }
  const paymentIntent = payment.paymentIntent
  const {orderId, returnUrl} = options
  const return_url = `${URL_PAYMENT_RESULT_CHECK}?orderId=${orderId}&redirect_uri=${encodeURIComponent(returnUrl)}`
  if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_confirmation') {
    const confirmPaymentRes = await stripe.confirmPayment({
      clientSecret: paymentIntent.client_secret,
      confirmParams: {
        return_url
      }
    })
    console.log("confirmPayment", confirmPaymentRes)
    await handlePaymentIntent(confirmPaymentRes, options)  // 递归调用
  } else if (paymentIntent.status === "succeeded") {
    window.location.href = return_url
  } else {
    /** 关闭遮罩 **/
    useBaseStore().updateIsShowModalLoadingProgress(false)
    console.error("error")
  }
}

function smartLookIdentify() {
  if (process.client) {
    const isLogin = useBaseStore().getterIsLogin
    if (isLogin) {
      const userInfo = useBaseStore().getterUserInfo
      SmartLook.identify(userInfo.userId, {
        UserID: userInfo.userId,
        Email: userInfo.email,
        DeviceID: useBaseStore().getterDeviceId
      });
      console.log('SmartLook is identified')
    }
  }
}

export async function hashDataClientSide(data) {
  const encoder = new TextEncoder();
  const dataEncode = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest('SHA-256', dataEncode);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}
