export function googleTrackPageView(route) {
  window.dataLayer.push({
    event: 'pageview',
    page: route.fullPath,
    title: document.title,
  });
}

export function googleTrackCreateAccount() {
  window.dataLayer.push({
    name: 'Create Account',
    event: 'Create Account',
    conversion_id: '16644568464',
    conversion_label: 'VMtbCJ3YotEZEJDr34A-'
  });
}

export function googleTrackSubscribe() {
  window.dataLayer.push({
    name: "Subscription",
    event: 'Subscription',
    conversion_id: '16644568464',
    conversion_label: 'YXiiCOfpotEZEJDr34A-'
  });
}

export function googleTrackJoinedWaitlist() {
  window.dataLayer.push({
    name: "Waitlist Sign Up",
    event: 'Waitlist Sign Up',
    conversion_id: '16644568464',
    conversion_label: 'Fp1uCOSIydEZEJDr34A-'
  });
}
