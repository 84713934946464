import {Device} from "~/utils/Device";
import _ from 'lodash'

export const useDevice = () => {
  return useState('device', () => {
    let userAgent;
    if (!_.isEmpty(useRequestHeaders())) userAgent = useRequestHeaders()['user-agent']
    if (navigator) userAgent = navigator.userAgent
    return Device(userAgent)
  })
}
