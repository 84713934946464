// plugins/errorHandler.js

export default defineNuxtPlugin((nuxtApp) => {
  // 捕获服务端错误
  nuxtApp.hook('app:error', (error) => {
    console.error('App Error:', error)
  })

  // 捕获渲染错误
  nuxtApp.hook('render:errorMiddleware', (error, req, res) => {
    console.error('Render Error:', error)
  })
})
