import {useBaseStore} from "~/stores/base";
import {v4 as uuidv4} from "uuid";
import {hashDataClientSide} from "~/utils/common";

// Add this before event code to all pages where PII data postback is expected and appropriate
// 将此事件前代码添加到所有预期和适当的PII数据回发页面
export async function tiktokPixelIdentify() {
  if (process.client) {
    const isLogin = useBaseStore().getterIsLogin
    if (isLogin) {
      const userInfo = useBaseStore().getterUserInfo
      // 验证并生成哈希的 email 和 userId
      const emailHash = await hashDataClientSide(userInfo.email);
      const userIdHash = await hashDataClientSide(userInfo.userId);
      ttq.identify({
        "email": emailHash,
        "external_id": userIdHash,
        "deduplication_key": uuidv4()
      });
      console.log('TikTok Pixel is identified')
    }
  }
}

export async function tiktokPixelTrack(event, options) {
  ttq.track(event, options)
}
